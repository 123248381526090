import React from "react";
import { useState, useEffect } from 'react';
import Coin from './report/authorsSection.js'
import { BrowserRouter, useHistory} from 'react-router-dom';




const App = () => {
  const [data, setData] = useState({})
  const [isOverLimit, setIsOverLimit] = useState(false)
  const [input, setInput] = useState("");
  const [isChecked] = useState({10: false, 20: false})
  const [isLoading, setIsLoading] = useState(true)  
  const [checkBoxOrder, setCheckBoxOrder] = useState([])
  const [checkedCoins, setCheckedCoins] = useState({});



  const limit = 200;
  const selectLimit = 30;

  useEffect(() => {
    let path = "https://cryptodevel.vacuumlabs.com/public/coin_data.json"
    fetch(path).then(response =>  response.json()).then(json => {
      setData(json);

      // Reordering coins based on ranks to display checkboxes
      let coinOrder = Array.apply(null, Array(200)).map(function () {})
      for (var coin in json) {
        coinOrder[json[coin]['rank']-1] = coin
      }

      let newCheckBoxOrder = []

      for (let i = 0; i < 200; i++) {
        if (coinOrder[i] != null) 
          newCheckBoxOrder = [...newCheckBoxOrder, coinOrder[i]]
      }

      setCheckBoxOrder(newCheckBoxOrder)

      let defaultCheckedCoins = {}
      for (const coin in json) {defaultCheckedCoins[coin] = false};

          // Search URL for params
      const queryParams = new URLSearchParams(window.location.search);
      let checked = queryParams.get("checked")
      let top = queryParams.get("top")
      if (checked != null) {
        if (checked.split(" ").length > selectLimit) {
          setIsOverLimit(true)
        }
        checked.split(" ").slice(0, selectLimit).forEach(c => defaultCheckedCoins[c.split("_").join(" ")] = true);
      } else if (top != null) {
        Object.keys(json).forEach((coin) => {if (json[coin]['rank'] <= top) defaultCheckedCoins[coin]= true})
        if (top == 10) isChecked[10] = true;
        else if (top == 20) isChecked[20] = true;
        else setIsLoading(false)
      } else setIsLoading(false)
      setCheckedCoins(defaultCheckedCoins)
      })
  },[])

  // Popup informing user that maximum number of coins was selected
  const Popup = () => {      
    return (
      <div class="popup" style={{  borderStyle: "solid", visibility: isOverLimit ? "visible": "hidden"}}>
          {'You can\'t select more than ' + selectLimit + ' coins.'}     
      </div>
    ) 
  }

  // Handles coin search input change
  function handleChange(event) {
    setInput(event.target.value);
  }

  // Handles change of 'Top 10' and 'Top 20' checkboxes
  function handleTopCheckboxChange(event) {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    // setCheckedCoins(new Set())

    let newCheckedCoins2 = {}
    for (const coin in data) {newCheckedCoins2[coin] = false};

    if (value) {
      if (name === "Top10") {
        Object.keys(data).forEach((coin) => {          
          if (data[coin]['rank'] <= 10) {
            newCheckedCoins2[coin] = true
          } else {
            newCheckedCoins2[coin] = false
          }
        })
      }
      if (name === "Top20") {
        Object.keys(data).forEach((coin) => {          
          if (data[coin]['rank'] <= 20) {
            newCheckedCoins2[coin] = true
          } else {
            newCheckedCoins2[coin] = false
          }
        })
      }
    }
    setCheckedCoins(newCheckedCoins2)
  }

  // Filtering of coins based on filter search input
  let filteredCoins = [];

  if (input === ""){
    for (const coin in checkBoxOrder){ 
      if (data[checkBoxOrder[coin]]['rank'] <= limit)
        filteredCoins.push(checkBoxOrder[coin]);
    }
  } else {
    const re = new RegExp(input, "i");
    for (const coin in checkBoxOrder){
      if (re.test(checkBoxOrder[coin]) && data[checkBoxOrder[coin]]['rank'] <= limit) {
        filteredCoins.push(checkBoxOrder[coin]);
      }
    }
  }

  // Checkboxes for specific coins
  const Check = () => {
    // Changes url based on checked checkboxes
    let history = useHistory();
    const onChange = (event) => {
      isChecked[10] = false;
      isChecked[20] = false;
      let newCheckedCoins2 = checkedCoins
      if (Object.keys(checkedCoins).filter(c => checkedCoins[c]).length  < selectLimit || !event.target.checked) { 
        setIsOverLimit(false);
        newCheckedCoins2[event.target.name] = event.target.checked
        setCheckedCoins({...checkedCoins, [event.target.name] : event.target.checked})
      } else {
        setIsOverLimit(true);
      }
      let path = Object.keys(checkedCoins).filter(coin => checkedCoins[coin]).map(coin => {return coin.split(" ").join("_")}).join("+")
            
      history.push({      
        pathname: '',
        search: path != '' ? "checked=" + path : path
      });
    } 

    return (
      <div>
        {filteredCoins.map(coin =>
        <div class="checkbox" >
          <label className="container">{coin}
            <input name={coin} type="checkbox" checked={checkedCoins[coin]} onChange={onChange}>
            </input>
          </label>
        </div>)}
      </div>
    )
  }

  // Checkboxes for Top 10 and Top 20 coins
  const CheckTopCoins = () => {
    let history = useHistory();
    const onChange = (event, n) => {
      isChecked[30-n] = false
      isChecked[n] = event.target.checked

      const path =  event.target.checked ? '?top=' + n : '';
      
      history.push({    
        pathname: '',
        search: path
      });
      handleTopCheckboxChange(event);
    }     

    return (
      <>
      <div class="checkbox">
        <label className="container">     
          Top 10
          <input name="Top10" type="checkbox" checked={isChecked[10]} onChange={e =>{onChange(e, 10)}}/>      
        </label>
      </div>

      <div class="checkbox">
        <label className="container"> 
          Top 20
          <input name="Top20" type="checkbox" checked={isChecked[20]} onChange={e =>{onChange(e, 20)}}/>         
        </label>
      </div>
      </>
    )
  }

  // Prevents reloading of page when enter is hit
  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
  <>
  <Popup/>
  <BrowserRouter>    
    <div style={{padding:10}}>
      {isLoading ? <> <div class="overlay">Cryptodevel <div class="center"><div class="center-vertically" style={{height: "100vh"}}><div class="spinner"></div></div></div></div> </> :
       <><div style={{display:"inline-block", margin:20}} > <h1>Filter</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Coin name: 
            <input value = {input} onChange={(event) => {handleChange(event)}} style={{marginLeft: 5}}/>
          </label>
        </form>
      </div>
      <h1>Select cryptos</h1>
      <CheckTopCoins/>
       </> }
      <Check />
    {Object.keys(data).length !== 0 && Object.keys(checkedCoins).filter(c => checkedCoins[c]).length > 0 ? 
        Object.keys(checkedCoins).filter(c => checkedCoins[c]).map(coin => {let out = <div key={coin}><h1>{coin}</h1><Coin {...data[coin]['data']}/></div>; if (isLoading) setIsLoading(false); return out}) :
        <div class="noCoinsSelected">No coins selected</div>}
       {!isLoading && <div id="methodology" style={{paddingTop: "20vh"}}>
          <h2> About </h2>
          <p> The statistics describe how intensively are individual cryptocurrencies being
          developed. For each project we analyze few of the most core repositories we could find. The
          most important metrics we are interested in are commits.</p>
          <p> The circles area corresponds number of commits, individual authors committed in the given month.
          Displayed is so-called normocommits count: normocommit is commit which happened at least 2 hours
          after some other normocommit of the same author. This measure is to filter out eager-committers who
          produce a lot of commits and skew statistics. The color of a circle corresponds to seniority of
          the current author: green = greenhorn, first month on the project. Black = senior, at least 6
          months on the project. </p>
          <p> The projects' order is according to total normocommits count in the recent 4 months. </p>
          <p> Apart of just commits we also analyze team activity on Github. This may be skewed, if
          the team does not use GH code reviews as their main review tool. This metric is reported on a
          quarterly basis.</p>
      </div>}
    </div>
  </BrowserRouter> 
  </>
  );
}

export default App;