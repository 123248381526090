import React, { Component, useEffect, useState } from "react";

// Returns color (intensity of green) based on seniority of author
function seniorityColor(props) {
    let val = (1-props.s)*255 
    return "rgb("+105/255*val+", "+ val + ", "+105/255*val+")"
}
 
// Draws circles representing project contributors
// per month for a speicified github repo
function drawCircles(props) {
    var circles = []
    for (var i = 0; i < props.centers.length; i++) {
        circles.push(drawCircle({centers : props.centers[i], maxHeight : props.maxHeight }))
    }
 
    return circles;
}
 
// Creates author label for circle from author's name
function authorLabel(props) {
    let parts = props.author.split(' ')
    if (parts.length > 1)
        return parts[0][0] + parts[1][0]
    return parts[0].slice(0,2)
}
 
// Draws circle representing project contributor
function drawCircle(props) {
    let r = props.centers[2] // radius
    let x = props.centers[0] // x-coordiante of center
    let y = props.maxHeight - props.centers[1] // y-coordinate of center
    let title  = props.centers[3]
    let author = props.centers[4]    
    let seniority   = props.centers[5]
 
    let label = authorLabel({author : author}) 
    let fill = seniorityColor({s:seniority})  
    var circleText = []
    // Display authors initials for large circles 
    if (r > 8) {
        let textColor = (seniority < 0.3) ? 'black' : 'white'
        circleText.push(
            <text x={x} y={y + r/3} font-family="Verdana" text-anchor="middle"
            alignment-baseline="middle" font-size={r+2} fill={textColor}> {label} <title> {title}
            </title> </text>
        )
    }
 
    return (<>
        <circle style={{fill: (fill)}} cx={x} cy={y} r={r}>
            <title> 
                {title} 
            </title>
        </circle> {circleText}
    </>)
}
 
// Draws repository state of a particular month
function drawAuthorsSection(props) {
    let title = <title>{props.centers.length} authors contributed</title>
 
    return  (
        <svg style={{width: (props.width), height: (props.maxHeight+1)}}> 
            <rect width={props.width} height={props.maxHeight} style={{fill: 'white'}}> 
                {title} 
            </rect> 
            {drawCircles(props)} 
        </svg>
    )
}
 
// Transforms github repo into URL
function linkFromRemote(props) {
    return props.replace('git@github.com:', 'https://github.com/')
}
 
// Draws row of repository states per each month
function drawRepoRow(props) {
    var row = []
    var n = Object.keys(props.data).length;
 
    for (var i = 0; i < n; i++) {
        const propsForAuthorsSection = {centers: props.data[Object.keys(props.data)[i]], width: 80, maxHeight: props.height}
        row.push(<td style={{width : 75}}> {drawAuthorsSection(propsForAuthorsSection)}</td>)
    }
 
    return (
        <tr>
            {row}
        </tr>
    )
}
 
// Fromats date. Example: from "2020-6" to "June 2020"
function formatDate(props) {
    const date = new Date(props)
    const formatedDate = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    return formatedDate
}
 
// Draws row of month labels on top of authors' sections
function drawMonthRow(props) {
    var headers = []
    var n = Object.keys(props).length;
 
    for (var i = 0; i < n; i++) {
        headers.push(<th style= {{width: 75}}> {formatDate(Object.keys(props)[i])} </th>)
    }
    return headers
 }
 
 // Draws row of repository states with month labels
function drawRepo(props) {
    const id = props.repoName 
    let pos = {top: 0, left: 0, x: 0, y: 0}

    // Mouse handler for click and drag functionality
    const mouseDownHandler = (e,ele) => {
        // Scrolls based on mouse movement when left button is 
        const mouseMoveHandler = (e) => {
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;
            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;       
        }
        // Stops dragging when left mouse button is released
        const mouseUpHandler = (e) => {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler); 
            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');
        }
 
        pos = {
            top:  ele.scrollTop,
            left: ele.scrollLeft,
            x: e.clientX,
            y: e.clientY,
        };
 
        ele.style.cursor = 'grabbing';
 
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    }
 
    return (<>
        <h3><a href={linkFromRemote(props.data.remote)} target="_blank" rel="noopener">{props.repoName}</a></h3>
        <div class='scrollable' id={id}
         style={{overflow: 'hidden', direction:'rtl'}}
         onMouseDown={(e)=> {
            const ele = document.getElementById(id); 
            mouseDownHandler(e,ele)}}>
            <table > 
                <tr> {drawMonthRow(props.data.data)} </tr>
                {drawRepoRow({data: props.data.data, height: props.data.height})} 
            </table>
        </div>
    </>)
}

class Coin extends Component {
    constructor(props) {
        super(props)
        this.isLoading = true;
        this.res = [];
        this.height = -18.72 // height of loading placeholder
        this.n = Object.keys(this.props).length
        for (var i = 0; i < this.n; i++) {
            this.height += 111.44 + this.props[Object.keys(this.props)[i]].height
        }
    }
    
    componentDidMount() {
        for (var i = 0; i < this.n; i++) {
            this.res.push(drawRepo({repoName: Object.keys(this.props)[i], data:  this.props[Object.keys(this.props)[i]]}))
        }
        if (this.isLoading) this.interval = setInterval(()=> {this.setState({}); clearInterval(this.interval)}, 500)
       this.isLoading = false
    }

    render() {
        let dots = <div class="center-vertically" style={{height: this.height}}><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
        return (this.isLoading ? dots : <>{this.res}</>)
    }
}

export default React.memo(Coin)